$(document).ready(function()
{      
      var x,left,down;

  $(".wine-slider").mousedown(function(e){
      e.preventDefault();
      down = true;
      x = e.pageX;
      left = $(this).scrollLeft();
  });

  $("body").mousemove(function(e){
    if (down) {
        var newX = e.pageX;
        $(".wine-slider").scrollLeft(left - newX + x);    
    }
  });

  $("body").mouseup(function(e){down = false;});

  var width = $(window).width()*0.80;

  $(".next").click(function () { 
    var leftPos = $('.wine-slider').scrollLeft();
    $(".wine-slider").animate({scrollLeft: leftPos + width}, 800);
  });

  $(".previous").on("click",function() {
    var leftPos = $('.wine-slider').scrollLeft();
    $(".wine-slider").animate({scrollLeft: leftPos - width}, 800);
  });
});